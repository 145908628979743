// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/not-found.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-portfolio-bsl-gov-js": () => import("./../../../src/pages/portfolio/bsl-gov.js" /* webpackChunkName: "component---src-pages-portfolio-bsl-gov-js" */),
  "component---src-pages-portfolio-buildup-js": () => import("./../../../src/pages/portfolio/buildup.js" /* webpackChunkName: "component---src-pages-portfolio-buildup-js" */),
  "component---src-pages-portfolio-citzbooking-js": () => import("./../../../src/pages/portfolio/citzbooking.js" /* webpackChunkName: "component---src-pages-portfolio-citzbooking-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-piclife-js": () => import("./../../../src/pages/portfolio/piclife.js" /* webpackChunkName: "component---src-pages-portfolio-piclife-js" */),
  "component---src-pages-portfolio-shoutcart-js": () => import("./../../../src/pages/portfolio/shoutcart.js" /* webpackChunkName: "component---src-pages-portfolio-shoutcart-js" */),
  "component---src-pages-portfolio-summer-catchers-js": () => import("./../../../src/pages/portfolio/summer-catchers.js" /* webpackChunkName: "component---src-pages-portfolio-summer-catchers-js" */),
  "component---src-pages-portfolio-tradee-js": () => import("./../../../src/pages/portfolio/tradee.js" /* webpackChunkName: "component---src-pages-portfolio-tradee-js" */),
  "component---src-pages-portfolio-ufo-crusher-js": () => import("./../../../src/pages/portfolio/ufo-crusher.js" /* webpackChunkName: "component---src-pages-portfolio-ufo-crusher-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-vacancies-vacancy-id-index-js": () => import("./../../../src/pages/vacancies/vacancy/:id/index.js" /* webpackChunkName: "component---src-pages-vacancies-vacancy-id-index-js" */),
  "component---src-pages-vacancy-js": () => import("./../../../src/pages/vacancy.js" /* webpackChunkName: "component---src-pages-vacancy-js" */)
}


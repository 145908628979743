import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PageContext = createContext({});

export const PageContextProvider = ({ pageContext, children }) => {
  const [page, setPage] = useState({ originalPath: '/', lang: 'en' });
  const { i18n } = useTranslation();

  useEffect(() => {
    if (pageContext.lang !== page.lang) {
      setPage(pageContext);
      i18n.changeLanguage(pageContext.lang);
    }
  }, [pageContext, i18n, page.lang]);

  return (
    <PageContext.Provider value={page}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);

import { combineReducers } from "redux";
import {timerReducer} from "./timerReduser/timerReducer";

export const rootReducer = combineReducers({
  testReducer: (state) => {
    if (state == null) {
      return []
    } else {
      return state;
    }
  },
  timerReducer
});

import { applyMiddleware, compose, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import { rootReducer } from "./reducers/reducers";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(ReduxThunk));

export default () => createStore(rootReducer, enhancer);

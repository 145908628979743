import {TIMER_IS_COMPLETE} from "../../action-types/action-types"

const initialTimeState = {
  isComplete: false
}


export const timerReducer = (state = initialTimeState, {type, payload}) => {
  switch (type) {
    case TIMER_IS_COMPLETE:
      return {...state, isComplete: payload}
    default:
      return {state}
  }
}

export const setTimerIsDone = (allReady) => ({type: TIMER_IS_COMPLETE, payload: allReady})
